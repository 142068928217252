body {
  /* background-color: black; */
}

.app {
  text-align: center;
  font-family: monospace;
  line-height: 1.4;

  height: 100%;
  margin-top: 50px;

  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
}

section {
  max-width: 48rem;
  margin: 0 auto;
}

footer {
  margin-top: 40px;
  padding: 2rem;
}

.hero {
  max-width: 28rem;
  margin: 0 auto;
}

.hero h1 {
  font-size: 70px;
  margin: 1.2rem 0 0 0;
  line-height: 1.2;
}

.note {
  max-width: 28rem;
  margin: 0 auto;

  padding: 0.9rem 1.3rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  text-align: left;
  font-style: italic;

  background-color: cornsilk;
}

.note .signature {
  text-align: right;
}

a {
  color: blue;
}

.mintBox {
  /* border: solid 1px; */
  margin-bottom: 20px;
  padding: 1rem;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
p {
  margin-bottom: 0.667rem;
}
